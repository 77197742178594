import ReactLazyPreload from 'helpers/reactLazyPreload';
import { FC, Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import { PublicRoutes } from './routes';

const LogInPage = ReactLazyPreload(() => import('pages/Auth/LogInPage'));
const ForgotPasswordPage = ReactLazyPreload(
  () => import('pages/Auth/ForgotPasswordPage'),
);

const AuthRouter: FC = () => {
  useEffect(() => {
    LogInPage.preload();
  }, []);

  return (
    <Suspense fallback={<AuthLayout />}>
      <Routes>
        <Route path={PublicRoutes.Home} element={<AuthLayout />}>
          <Route index element={<LogInPage />} />
          <Route path={PublicRoutes.LogIn} element={<LogInPage />} />
          <Route
            path={PublicRoutes.ForogotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path="*" element={<LogInPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AuthRouter;
