import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { Box, Drawer as MUIDrawer } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import DrawerItems from './DrawerItems';

export interface DrawerRef {
  toggle: () => void;
}

const Drawer = forwardRef<DrawerRef, PropsWithChildren>((_, ref) => {
  const {
    themeConstants: { drawerWidth },
    createSxStyles,
  } = useThemeContext();
  const { boxStyles, drawerStyles } = createSxStyles({
    boxStyles: { width: { lg: drawerWidth }, flexShrink: { sm: 0 } },
    drawerStyles: {
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: drawerWidth,
      },
    },
  });

  const { isMD } = useWindowDimensions();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToggle = useCallback(() => {
    if (!isMD) {
      return;
    }
    setMobileOpen((oldValue) => !oldValue);
  }, [isMD]);

  useImperativeHandle(
    ref,
    () => ({
      toggle: handleDrawerToggle,
    }),
    [handleDrawerToggle],
  );

  return (
    <Box component="nav" sx={boxStyles}>
      <MUIDrawer
        variant={`${isMD ? 'temporary' : 'permanent'}`}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={drawerStyles}
      >
        <DrawerItems handleDrawerToggle={handleDrawerToggle} />
      </MUIDrawer>
    </Box>
  );
});

export default Drawer;
