import { ModalsState } from 'types/store/modalsSliceTypes';

const modalInitialState = {
  open: false,
  id: '',
};

export const transactionModalInitalState = {
  modal: modalInitialState,
  data: {
    accountId: '',
    description: '',
    transactionAmount: '',
    dateCreated: '',
    status: '',
    linkedReward: '',
  },
};

export const redemptionModalInitialState = {
  modal: modalInitialState,
  data: {
    brokerageId: '',
    ticker: '',
    amountRedeemed: '',
    dateSubmitted: '',
    status: '',
    linkedRewards: [],
  },
};

export const rewardModalInitialState = {
  modal: modalInitialState,
  data: {
    brokerageId: '',
    ticker: '',
    amountRewarded: '',
    dateCreated: '',
    status: '',
    linkedTrasaction: '',
    linkedRedemption: '',
  },
};

export const funginModalInitialState = {
  modal: modalInitialState,
  data: {
    routingNumber: '',
    accountNumber: '',
    sweepAccountInfio: '',
  },
};

const modalsInitialState: ModalsState = {
  transactionModal: transactionModalInitalState,
  redemptionModal: redemptionModalInitialState,
  rewardModal: rewardModalInitialState,
  fundingModal: funginModalInitialState,
};

export default modalsInitialState;
