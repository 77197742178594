import { css, CSSObject } from '@emotion/react';

type NamedStyles<T> = { [P in keyof T]: CSSObject };
export type CreateEmotionCssStyles = <T extends NamedStyles<T>>(
  styles: NamedStyles<T>,
) => NamedStyles<T>;

const getTypedKeys = <T>(
  styles: NamedStyles<T>,
): Array<keyof NamedStyles<T>> => {
  const keys = Object.keys(styles);

  return keys as (keyof T)[];
};

const createEmotionCssStyles = <T extends NamedStyles<T>>(
  styles: NamedStyles<T>,
) => {
  const keys = getTypedKeys(styles);
  const serilaised = keys.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: css(styles[curr]),
    }),
    {} as NamedStyles<T>,
  );
  return serilaised;
};

export default createEmotionCssStyles;
