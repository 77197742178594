import { SxProps, Theme } from '@mui/material';

export type MergeSxStyles = (sxStylesArr: SxProps<Theme>[]) => SxProps<Theme>;

const mergeSxStyles = (sxStylesArr: SxProps<Theme>[]): SxProps<Theme> => {
  const sxStyles = sxStylesArr.map((sxStyle) => {
    const [style] = Array.isArray(sxStyle) ? sxStyle : [sxStyle];
    return style;
  });

  return sxStyles;
};

export default mergeSxStyles;
