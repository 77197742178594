import { FC, ReactNode, memo } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface DrawerItemProps {
  text: string;
  ItemIcon: ReactNode;
  selected: boolean;
  onClick: () => void;
}

const DrawerItem: FC<DrawerItemProps> = ({
  text,
  ItemIcon,
  selected,
  onClick,
}) => (
  <ListItem disablePadding selected={selected}>
    <ListItemButton onClick={onClick}>
      <ListItemIcon>{ItemIcon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  </ListItem>
);

export default memo(DrawerItem);
