import { useThemeContext } from 'providers/ThemeProvider';
import { useLayoutEffect, useMemo, useState } from 'react';

const useWindowDimensions = () => {
  const {
    theme: {
      breakpoints: {
        values: { sm, md, lg },
      },
    },
  } = useThemeContext();

  const [dimensions, setDimensions] = useState<[number, number]>([0, 0]);
  const isXS = useMemo(() => dimensions[0] < sm, [dimensions, sm]);
  const isSM = useMemo(() => dimensions[0] < md, [dimensions, md]);
  const isMD = useMemo(() => dimensions[0] < lg, [dimensions, lg]);

  useLayoutEffect(() => {
    const updateDimensions = () => {
      setDimensions([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return {
    windowWidth: dimensions[0],
    windowHeight: dimensions[1],
    isXS,
    isSM,
    isMD,
  };
};

export default useWindowDimensions;
