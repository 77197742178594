import { FC } from 'react';
import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material/';
import { useThemeContext } from 'providers/ThemeProvider';

interface SettingsWithAvatarProps {
  primaryText: string;
  onClick: () => void;
  avatarImgSrc: string;
  avatarAlt: string;
  avatarFallbackText?: string;
  secondaryText?: string;
}

const SettingsWithAvatar: FC<SettingsWithAvatarProps> = ({
  primaryText,
  onClick,
  avatarImgSrc,
  avatarAlt,
  avatarFallbackText,
  secondaryText,
}) => {
  const { createSxStyles } = useThemeContext();
  const { listItem, listItemIcon } = createSxStyles({
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    listItemIcon: {
      minWidth: 0,
    },
  });

  return (
    <ListItem sx={listItem}>
      <ListItemButton onClick={onClick}>
        <ListItemAvatar>
          <Avatar alt={avatarAlt} src={avatarImgSrc}>
            {avatarFallbackText}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ListItemIcon sx={listItemIcon}>
          <SettingsIcon />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};

export default SettingsWithAvatar;
