const layoutConstants = {
  drawerWidth: 256,
  contentHorizontalPadding: 48,
};

const chartConstants = {
  chartAspectRatio: 216 / 1060,
  chartMargin: {
    top: 10,
    left: 20,
    bottom: 30,
    right: 0,
  },
};

const themeConstants = {
  ...layoutConstants,
  ...chartConstants,
};

export default themeConstants;
