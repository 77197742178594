import { FC, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from './routes';

const protectedPaths: string[] = Object.values(ProtectedRoutes);

const RedirectOnMount: FC = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  // In development build, because of the strict mode it gets mounted twice, not happening in production mode
  const secondLoad = useRef<boolean>(false);
  useEffect(() => {
    if (secondLoad.current) {
      return;
    }
    if (!secondLoad.current) {
      secondLoad.current = true;
    }

    if (protectedPaths.includes(pathname)) {
      return;
    }

    navigate('/', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectOnMount;
