import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from 'types/store/authSliceTypes';
import authInitialState from './authInitialState';

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setAuthLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authLoading: action.payload,
    }),
    setAuthError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authError: action.payload,
    }),
    setRememberMe: (state, action: PayloadAction<boolean>) => ({
      ...state,
      rememberMe: action.payload,
    }),
    setStoredEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      storedEmail: action.payload,
    }),
    updateAuthState: (state, action: PayloadAction<Partial<AuthState>>) => ({
      ...state,
      ...action.payload,
    }),
    logOut: (state) => state,
  },
});

export const {
  setAuthLoading: setAuthLoadingAction,
  setAuthError: setAuthErrorAction,
  setRememberMe: setRememberMeAction,
  setStoredEmail: setStoredEmailAction,
  updateAuthState: updateAuthStateAction,
  logOut: logOutAction,
} = authSlice.actions;

export default authSlice.reducer;
