import { FC } from 'react';
import BitsLogo from 'components/page/BitsLogo';
import { useThemeContext } from 'providers/ThemeProvider';
import { Outlet } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';

const AuthLayout: FC = () => {
  const { createSxStyles } = useThemeContext();
  const { container, header } = createSxStyles({
    container: {
      p: 2,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      gap: 2,
      mb: 4,
    },
  });

  return (
    <Grid container sx={container}>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={header}>
          <BitsLogo />
          <Typography variant="h6">Bits of Stock Sandbox Dashboard</Typography>
        </Box>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
