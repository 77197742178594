import { FC, useCallback } from 'react';
import List from '@mui/material/List';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { Box, Toolbar } from '@mui/material';
import DrawerItem from 'components/drawer/DrawerItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'router/routes';
import BitsLogoDrawer from 'components/drawer/BitsLogoDrawer';
import { useThemeContext } from 'providers/ThemeProvider';
import SettingsWithAvatar from './SettingsWithAvatar';

const drawerItemsData = [
  {
    text: 'Dashboard',
    ItemIcon: <DashboardIcon />,
    navigateTo: ProtectedRoutes.Dashboard,
  },
  {
    text: 'Users',
    ItemIcon: <PeopleIcon />,
    navigateTo: ProtectedRoutes.Users,
  },
  {
    text: 'Transactions',
    ItemIcon: <ReceiptIcon />,
    navigateTo: ProtectedRoutes.Transactions,
  },
  {
    text: 'Rewards',
    ItemIcon: <AttachMoneyIcon />,
    navigateTo: ProtectedRoutes.Rewards,
  },
  {
    text: 'Redemptions',
    ItemIcon: <PaymentIcon />,
    navigateTo: ProtectedRoutes.Redemptions,
  },
  {
    text: 'Funding',
    ItemIcon: <PaymentIcon />,
    navigateTo: ProtectedRoutes.Funding,
  },
];

interface DrawerItemsProps {
  handleDrawerToggle: () => void;
}

const DrawerItems: FC<DrawerItemsProps> = ({ handleDrawerToggle }) => {
  const { createSxStyles } = useThemeContext();
  const { container, toolbar, list, bottomContainer } = createSxStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    toolbar: {
      mt: 1,
    },
    list: {
      width: '100%',
    },
    bottomContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  });

  const { pathname: locationPathname } = useLocation();

  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (to: string) => {
      navigate(to);
      handleDrawerToggle();
    },
    [navigate, handleDrawerToggle],
  );

  const handleLogoOnClick = useCallback(() => {
    navigate(ProtectedRoutes.Dashboard);
  }, [navigate]);

  const handlePartnerSettingsOnClick = useCallback(() => {
    navigate(ProtectedRoutes.PartnerSettings);
  }, [navigate]);

  const handleUSerSettingsOnClick = useCallback(() => {
    navigate(ProtectedRoutes.UserSettings);
  }, [navigate]);

  return (
    <Box sx={container}>
      <Toolbar disableGutters sx={toolbar}>
        <List sx={list}>
          <BitsLogoDrawer onClick={handleLogoOnClick} />
          <SettingsWithAvatar
            onClick={handlePartnerSettingsOnClick}
            primaryText="Partner Name"
            avatarImgSrc="assets/non-existent.png"
            avatarAlt="Partner Name"
            avatarFallbackText="PP"
          />
        </List>
      </Toolbar>
      <List component="nav">
        {drawerItemsData.map((item) => (
          <DrawerItem
            key={`${item.text}-sidebarMenuItem`}
            text={item.text}
            ItemIcon={item.ItemIcon}
            selected={locationPathname === item.navigateTo}
            onClick={() => handleNavigate(item.navigateTo)}
          />
        ))}
      </List>
      <Box sx={bottomContainer}>
        <SettingsWithAvatar
          onClick={handleUSerSettingsOnClick}
          primaryText="User Name"
          secondaryText="user@email.com"
          avatarImgSrc="assets/non-existent.png"
          avatarAlt="partner user"
          avatarFallbackText="PU"
        />
      </Box>
    </Box>
  );
};

export default DrawerItems;
