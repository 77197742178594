enum ProtectedRoutes {
  Dashboard = '/',
  Users = '/users',
  User = '/users/:userId',
  Transactions = '/transactions',
  Rewards = '/rewards',
  Redemptions = '/redemptions',
  PartnerSettings = '/partner-settings',
  UserSettings = '/user-settings',
  Funding = '/funding',
  LinkedRewards = '/linked-rewards',
}

enum PublicRoutes {
  Home = '/',
  LogIn = '/login',
  ForogotPassword = '/forgot-password',
}

export { ProtectedRoutes, PublicRoutes };
