import { AuthState } from 'types/store/authSliceTypes';

const authInitialState: AuthState = {
  authLoading: false,
  authError: false,
  loggedIn: false,
  token: {
    access: '',
    refresh: '',
  },
  rememberMe: false,
  storedEmail: '',
  loggedOut: false,
};

export default authInitialState;
