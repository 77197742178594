import { FC, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { enableMetricsAction } from 'store/slices/general/generalSlice';

const UserSettingsProvider: FC = () => {
  const metricsEnabled = useAppSelector(
    (state) => state.general.featureFlags.metricsEnabled,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const expMetricsEnabled = localStorage.getItem('metricsEnabled');
    if (expMetricsEnabled === null) {
      return;
    }

    const expMetricsEnabledBool = expMetricsEnabled === 'true';

    dispatch(enableMetricsAction(expMetricsEnabledBool));
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('metricsEnabled', JSON.stringify(metricsEnabled));
  }, [metricsEnabled]);

  return null;
};

export default UserSettingsProvider;
