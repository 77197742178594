import { FC } from 'react';
import { AppBar as MUIAppBar, IconButton, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useThemeContext } from 'providers/ThemeProvider';

interface AppBarProps {
  handleDrawerToggle: () => void;
}

const AppBar: FC<AppBarProps> = ({ handleDrawerToggle }) => {
  const { createSxStyles } = useThemeContext();
  const { toolbarStyles, iconButtonStyles } = createSxStyles({
    toolbarStyles: {
      justifyContent: 'flex-start',
    },
    iconButtonStyles: {
      mr: 2,
    },
  });

  return (
    <MUIAppBar position="fixed">
      <Toolbar sx={toolbarStyles}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={iconButtonStyles}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
