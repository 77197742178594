import { AppThunk } from 'store/store';
import { logInUrl, refreshTokenUrl, apiKey } from 'constants/apis';
import { AuthToken } from 'types/commonTypes';
import {
  setAuthLoadingAction,
  setAuthErrorAction,
  updateAuthStateAction,
} from './authSlice';

interface LogInThunkParams {
  email: string;
  password: string;
}

export const logInThunk =
  ({ email, password }: LogInThunkParams): AppThunk =>
  async (dispatch) => {
    if (!email || !password) {
      dispatch(setAuthErrorAction(true));
      return;
    }

    dispatch(setAuthLoadingAction(true));

    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({ email, password }),
      };

      const response = await fetch(logInUrl, options);
      const paresedResponse = await response.json();

      if (
        (response.status < 200 || response.status > 299) &&
        response.status !== 404
      ) {
        throw Error('Error in log in call.');
      }

      dispatch(
        updateAuthStateAction({
          loggedIn: true,
          token: paresedResponse,
          loggedOut: false,
          authError: false,
        }),
      );
    } catch (error) {
      dispatch(setAuthErrorAction(true));
    }

    dispatch(setAuthLoadingAction(false));
  };

export const refreshTokenThunk =
  (token: AuthToken): AppThunk =>
  async (dispatch) => {
    if (!token.access || !token.refresh) {
      return;
    }

    const { refresh } = token;

    dispatch(setAuthLoadingAction(true));

    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({ refresh }),
      };

      const response = await fetch(refreshTokenUrl, options);
      const paresedResponse = await response.json();

      if (
        (response.status < 200 || response.status > 299) &&
        response.status !== 404
      ) {
        throw Error('Error in refresh token call.');
      }

      dispatch(
        updateAuthStateAction({
          loggedIn: true,
          token: {
            access: paresedResponse.access,
            refresh,
          },
          loggedOut: false,
        }),
      );
    } catch (error) {
      dispatch(
        updateAuthStateAction({
          loggedIn: false,
          token: {
            access: '',
            refresh: '',
          },
        }),
      );
    }

    dispatch(setAuthLoadingAction(false));
  };
