import authReducer from 'store/slices/auth/authSlice';
import generalReducer from 'store/slices/general/generalSlice';
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { ModalsState } from 'types/store/modalsSliceTypes';
import { AuthState } from 'types/store/authSliceTypes';
import { GeneralState } from 'types/store/generalSliceTypes';
import authInitialState from './slices/auth/authInitialState';
import generalInitialState from './slices/general/generalInitialState';
import { logOutAction } from './slices/auth/authSlice';
import modalsInitialState from './slices/modals/modalsInitialState';
import modalsReducer from './slices/modals/modalsSlice';

type StateType = {
  auth: AuthState;
  general: GeneralState;
  modals: ModalsState;
};

const initialState: StateType = {
  auth: authInitialState,
  general: generalInitialState,
  modals: modalsInitialState,
};

const reducers = combineReducers({
  auth: authReducer,
  general: generalReducer,
  modals: modalsReducer,
});

const rootReducer = (state: StateType | undefined, action: AnyAction) => {
  if (state === undefined) {
    return initialState;
  }

  if (action.type === logOutAction.type) {
    return {
      ...state,
      auth: {
        ...authInitialState,
        rememberMe: state.auth.rememberMe,
        storedEmail: state.auth.storedEmail,
        loggedOut: true,
      },
      general: generalInitialState,
      modals: modalsInitialState,
    };
  }

  return reducers(state, action);
};

export default rootReducer;
