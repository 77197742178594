import { FC, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { refreshTokenThunk } from 'store/slices/auth/authThunks';
import { updateAuthStateAction } from 'store/slices/auth/authSlice';

const AuthProvider: FC = () => {
  const dispatch = useAppDispatch();
  const { token, rememberMe, storedEmail, loggedIn, loggedOut } =
    useAppSelector((state) => state.auth);

  // In development build, because of the strict mode it gets mounted twice, not happening in production mode
  const secondLoadGet = useRef<boolean>(false);
  useEffect(() => {
    if (secondLoadGet.current) {
      return;
    }
    if (!secondLoadGet.current) {
      secondLoadGet.current = true;
    }

    const tokenLocalStorage = localStorage.getItem('token');
    const rememberMeLocalStorage = localStorage.getItem('rememberMe');
    const storedEmailLocalStorage = localStorage.getItem('storedEmail');

    if (tokenLocalStorage) {
      dispatch(refreshTokenThunk(JSON.parse(tokenLocalStorage)));
    }

    if (rememberMeLocalStorage || storedEmailLocalStorage) {
      dispatch(
        updateAuthStateAction({
          rememberMe: true,
          storedEmail: storedEmailLocalStorage || '',
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!token.access && !!token.refresh) {
      localStorage.setItem('token', JSON.stringify(token));
    }
  }, [token]);

  useEffect(() => {
    if (!loggedIn && loggedOut) {
      localStorage.removeItem('token');
    }
  }, [loggedIn, loggedOut]);

  useEffect(() => {
    if (!rememberMe) {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('storedEmail');
    }

    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('storedEmail', storedEmail);
    }
  }, [rememberMe, storedEmail]);

  return null;
};

export default AuthProvider;
