import { ComponentType, lazy, LazyExoticComponent } from 'react';

interface LazyPreload<Props> extends LazyExoticComponent<ComponentType<Props>> {
  preload: () => void;
}

function ReactLazyPreload<Props>(
  importStatement: () => Promise<{ default: ComponentType<Props> }>,
) {
  const Component: LazyPreload<Props> = Object.assign(lazy(importStatement), {
    preload: importStatement,
  });

  return Component;
}

export default ReactLazyPreload;
