import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetModalVisibilityActionPayload } from 'types/store/modalsSliceTypes';
import modalsInitialState, {
  funginModalInitialState,
  redemptionModalInitialState,
  rewardModalInitialState,
  transactionModalInitalState,
} from './modalsInitialState';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: modalsInitialState,
  reducers: {
    setModalVisibility: (
      state,
      action: PayloadAction<SetModalVisibilityActionPayload>,
    ) => ({
      ...state,
      transactionModal:
        action.payload.type === 'transactionModal'
          ? {
              ...state.transactionModal,
              modal: action.payload.data,
              data: !action.payload.data.open
                ? transactionModalInitalState.data
                : state.transactionModal.data,
            }
          : transactionModalInitalState,
      redemptionModal:
        action.payload.type === 'redemptionModal'
          ? {
              ...state.redemptionModal,
              modal: action.payload.data,
              data: !action.payload.data.open
                ? redemptionModalInitialState.data
                : state.redemptionModal.data,
            }
          : redemptionModalInitialState,
      rewardModal:
        action.payload.type === 'rewardModal'
          ? {
              ...state.rewardModal,
              modal: action.payload.data,
              data: !action.payload.data.open
                ? rewardModalInitialState.data
                : state.rewardModal.data,
            }
          : rewardModalInitialState,
      fundingModal:
        action.payload.type === 'fundingModal'
          ? {
              ...state.fundingModal,
              modal: action.payload.data,
              data: !action.payload.data.open
                ? funginModalInitialState.data
                : state.fundingModal.data,
            }
          : funginModalInitialState,
    }),
  },
});

export const { setModalVisibility: setModalVisibillityAction } =
  modalsSlice.actions;

export default modalsSlice.reducer;
