import { FC, Suspense } from 'react';
import LoadingOverlay from 'components/progressIndicators/LoadingOverlay';
import ReactLazyPreload from 'helpers/reactLazyPreload';
import { useAppSelector } from 'store/hooks';

const TransactionModal = ReactLazyPreload(
  () => import('modals/TransactionModal'),
);
const RewardModal = ReactLazyPreload(() => import('modals/RewardModal'));
const RedemptionModal = ReactLazyPreload(
  () => import('modals/RedemptionModal'),
);
const FundingModal = ReactLazyPreload(() => import('modals/FundingModal'));

const ModalProvider: FC = () => {
  const {
    transactionModal: {
      modal: { open: transactionModalOpen },
    },
    rewardModal: {
      modal: { open: rewardModalOpen },
    },
    redemptionModal: {
      modal: { open: redemptionModalOpen },
    },
    fundingModal: {
      modal: { open: fundingModalOpen },
    },
  } = useAppSelector((state) => state.modals);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {transactionModalOpen && <TransactionModal />}
      {rewardModalOpen && <RewardModal />}
      {redemptionModalOpen && <RedemptionModal />}
      {fundingModalOpen && <FundingModal />}
    </Suspense>
  );
};

export default ModalProvider;
