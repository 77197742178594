import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import AppRouter from 'router/AppRouter';
import AuthRouter from 'router/AuthRouter';
import LoadingOverlay from 'components/progressIndicators/LoadingOverlay';

const App: FC = () => {
  const { loggedIn, authLoading } = useAppSelector((state) => state.auth);

  if (authLoading) return <LoadingOverlay />;

  return (
    <BrowserRouter>{loggedIn ? <AppRouter /> : <AuthRouter />}</BrowserRouter>
  );
};

export default App;
