import SnackBarNotification from 'components/notifications/SnackBarNotification';
import { FC, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { snackBarInitialState } from 'store/slices/general/generalInitialState';
import { setSnackBarAction } from 'store/slices/general/generalSlice';

const NotificationProvider: FC = () => {
  const { open, severity, message } = useAppSelector(
    (state) => state.general.snackBar,
  );
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(setSnackBarAction(snackBarInitialState));
  }, [dispatch]);

  return (
    <SnackBarNotification
      open={open}
      onClose={handleClose}
      severity={severity}
      message={message}
    />
  );
};

export default NotificationProvider;
