import { FC, Suspense, useEffect } from 'react';
import SidebarLayout from 'layouts/SidebarLayout';
import ReactLazyPreload from 'helpers/reactLazyPreload';
import { ProtectedRoutes } from 'router/routes';
import { Route, Routes } from 'react-router-dom';
import RedirectOnMount from './RedirectOnMount';

const DashboardScreen = ReactLazyPreload(
  () => import('pages/App/DashboardPage'),
);
const UsersPage = ReactLazyPreload(() => import('pages/App/UsersPage'));
const UserPage = ReactLazyPreload(() => import('pages/App/UserPage'));
const TransactionsPage = ReactLazyPreload(
  () => import('pages/App/TransactionsPage'),
);
const RewardsPage = ReactLazyPreload(() => import('pages/App/RewardsPage'));
const RedemptionsPage = ReactLazyPreload(
  () => import('pages/App/RedemptionsPage'),
);
const PartnerSettingsPage = ReactLazyPreload(
  () => import('pages/App/PartnerSettingsPage'),
);
const UserSettingsPage = ReactLazyPreload(
  () => import('pages/App/UserSettingsPage'),
);
const FundingPage = ReactLazyPreload(() => import('pages/App/FundingPage'));
const LinkedRewardsPage = ReactLazyPreload(
  () => import('pages/App/LinkedRewardsPage'),
);
const NotFoundScreen = ReactLazyPreload(() => import('pages/App/NotFoundPage'));

const AppRouter: FC = () => {
  useEffect(() => {
    DashboardScreen.preload();
  }, []);

  return (
    <Suspense fallback={<SidebarLayout />}>
      <RedirectOnMount />
      <Routes>
        <Route path={ProtectedRoutes.Dashboard} element={<SidebarLayout />}>
          <Route index element={<DashboardScreen />} />
          <Route path={ProtectedRoutes.Users} element={<UsersPage />} />
          <Route path={ProtectedRoutes.User} element={<UserPage />} />
          <Route
            path={ProtectedRoutes.Transactions}
            element={<TransactionsPage />}
          />
          <Route path={ProtectedRoutes.Rewards} element={<RewardsPage />} />
          <Route
            path={ProtectedRoutes.Redemptions}
            element={<RedemptionsPage />}
          />
          <Route path={ProtectedRoutes.Funding} element={<FundingPage />} />
          <Route
            path={ProtectedRoutes.PartnerSettings}
            element={<PartnerSettingsPage />}
          />
          <Route
            path={ProtectedRoutes.UserSettings}
            element={<UserSettingsPage />}
          />
          <Route
            path={ProtectedRoutes.LinkedRewards}
            element={<LinkedRewardsPage />}
          />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
