import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackBar } from 'types/store/generalSliceTypes';
import generalInitialState from './generalInitialState';

const generalSlice = createSlice({
  name: 'general',
  initialState: generalInitialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setSnackBar: (state, action: PayloadAction<SnackBar>) => ({
      ...state,
      snackBar: action.payload,
    }),
    enableMetrics: (state, action: PayloadAction<boolean>) => ({
      ...state,
      featureFlags: {
        ...state.featureFlags,
        metricsEnabled: action.payload,
      },
    }),
  },
});

export const {
  setLoading: setLoadingAction,
  setSnackBar: setSnackBarAction,
  enableMetrics: enableMetricsAction,
} = generalSlice.actions;

export default generalSlice.reducer;
