import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import App from 'App';
import GlobalStore from 'store/store';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from 'providers/ThemeProvider';
import AuthProvider from 'providers/AuthProvider';
import UserSettingsProvider from 'providers/UserSettingsProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'styleFixes.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <GlobalStore>
      <ThemeProvider>
        <CssBaseline enableColorScheme />
        <AuthProvider />
        <UserSettingsProvider />
        <App />
      </ThemeProvider>
    </GlobalStore>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
