import { FC, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBar from 'components/layout/AppBar';
import Drawer, { DrawerRef } from 'components/drawer/Drawer';
import { useThemeContext } from 'providers/ThemeProvider';
import { Outlet } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ModalProvider from 'providers/ModalProvider';
import NotificationProvider from 'providers/NotificationProvider';

const SidebarLayout: FC = () => {
  const drawerRef = useRef<DrawerRef>(null);
  const handleDrawerToggle = useCallback(() => drawerRef.current?.toggle(), []);

  const { isMD } = useWindowDimensions();

  const {
    themeConstants: { drawerWidth },
    createSxStyles,
  } = useThemeContext();

  const { flexContainer, contentStyle } = createSxStyles({
    flexContainer: {
      display: 'flex',
      minHeight: '100vh',
    },
    contentStyle: {
      flexGrow: 1,
      p: 3,
      width: { lg: `calc(100% - ${drawerWidth}px)` },
      bgcolor: 'background.paper',
    },
  });

  return (
    <Box sx={flexContainer}>
      {isMD && <AppBar handleDrawerToggle={handleDrawerToggle} />}
      <Drawer ref={drawerRef} />
      <Box component="main" sx={contentStyle}>
        {isMD && <Toolbar />}
        <Outlet />
        <NotificationProvider />
        <ModalProvider />
      </Box>
    </Box>
  );
};

export default SidebarLayout;
