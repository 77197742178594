import { FC } from 'react';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import BitsLogo from 'components/page/BitsLogo';
import { useThemeContext } from 'providers/ThemeProvider';

interface BitsLogoProps {
  onClick?: () => void;
}

const BitsLogoDrawer: FC<BitsLogoProps> = ({ onClick }) => {
  const { createSxStyles } = useThemeContext();
  const { title } = createSxStyles({
    title: {
      ml: 2,
    },
  });

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <BitsLogo />
        <Typography variant="overline" sx={title}>
          SANDBOX
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default BitsLogoDrawer;
