import { CSSProperties } from 'react';

type NamedStyles<T> = { [P in keyof T]: CSSProperties };
export type CreateCssStyles = <T extends NamedStyles<T>>(
  styles: NamedStyles<T>,
) => NamedStyles<T>;

const createCssStyles = <T extends NamedStyles<T>>(styles: NamedStyles<T>) =>
  styles;

export default createCssStyles;
