import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingOverlay: FC = () => (
  <Box>
    <CircularProgress
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    />
  </Box>
);

export default LoadingOverlay;
