import { GeneralState } from 'types/store/generalSliceTypes';

export const snackBarInitialState = {
  open: false,
  severity: undefined,
  message: '',
};

const generalInitialState: GeneralState = {
  loading: false,
  featureFlags: {
    metricsEnabled: false,
  },
  snackBar: snackBarInitialState,
};

export default generalInitialState;
