import { FC } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface SnackBarNotificationProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: AlertColor | undefined;
}

const SnackBarNotification: FC<SnackBarNotificationProps> = ({
  open,
  onClose,
  message,
  severity,
}) => (
  <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity}>
      {message}
    </Alert>
  </Snackbar>
);

export default SnackBarNotification;
